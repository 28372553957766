import { collection, doc, DocumentData, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { mapValues, groupBy, omit, sortBy, orderBy } from "lodash";
import moment from "moment";
import { firestore } from "../utils/Firebase";

const store = firestore();
const collectionRef = collection(store, 'orders');

export const getOrders = async () => {
    let orderList: DocumentData[] = [];
    const orders = await getDocs(collectionRef);
    orders.forEach(
        res => orderList.push(res.data())
    )
    return orderList;
}

export const setOrderStatus = async (orderIDList: string[]) => {
    for (const orderID of orderIDList) {
        const order = await getDoc(doc(collectionRef, orderID));
        await updateDoc(order.ref, { status: 'Exported' });
    }
    return 'Completed'
}

export const orderMetrics = async () => {
    const orderResponse = await getDocs(collectionRef);
    const today = moment().format('DD-MM-YYYY');
    const q = query(collectionRef, where("orderDate", "==", today));
    const ordersToday = await getDocs(q);
    return {
        totalOrders: orderResponse.size,
        todaysOrders: ordersToday.size
    }
}

export const salesTrend = async () => {
    const orderData: any[] = [];
    const orderResponse = await getDocs(collectionRef);
    const orders = orderResponse.docs
    orders.forEach(order => orderData.push(order.data()))
    let grouped = mapValues(groupBy(orderData, 'orderDate'),
        olist => olist.map(order => omit(order, 'orderDate'))
    );
    let values = Object.entries(grouped).map(i => Object.assign({ 'date': moment(i[0], 'DD-MM-YYYY').format('MM-DD-YYYY'), 'orders': i[1].length }));
    let ordered = orderBy(values, (obj) => moment(obj.date), ['asc']);
    ordered.splice(0, ordered.length - 14);
    return ordered
}

export const orderStatusGroup = async () => {
    const orderData: any[] = [];
    const orderResponse = await getDocs(collectionRef);
    const orders = orderResponse.docs
    orders.forEach(order => orderData.push(order.data()))
    let grouped = mapValues(groupBy(orderData, 'status'),
        olist => olist.map(order => omit(order, 'status'))
    );
    return Object.entries(grouped).map(i => Object.assign({ 'name': i[0], 'value': i[1].length }));
}