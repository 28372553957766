import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiFillDollarCircle } from 'react-icons/ai';
import { BsPatchCheckFill } from 'react-icons/bs';
import { FiPackage } from 'react-icons/fi';
import { RiLineChartLine } from 'react-icons/ri';
import { Tile } from '../components/Tile';
import { orderMetrics, orderStatusGroup, salesTrend } from '../services/OrderService';
import { productMetrics } from '../services/ProductService';

interface TileProps {
    totalOrders?: number;
    todaysOrders?: number;
    totalProducts?: number;
    activeProducts?: number;
}

export const Dashboard = () => {
    const [orderData, setOrderData] = useState<{ totalOrders: number; todaysOrders: number }>();
    const [productData, setProductData] = useState<{ totalProducts: number; activeProducts: number }>();
    const [orderTrend, setOrderTrend] = useState<{ date: string, orders: number }[]>();
    const [orderStatus, setOrderStatus] = useState<{ status: string, count: number }[]>()

    const [isLoading, setIsLoading] = useState(true);

    const options = {
        grid: { top: 8, right: 8, bottom: 24, left: 36 },
        xAxis: {
            type: 'category',
            data: orderTrend?.map(i => moment(i.date).format('DD-MMM-YYYY')),
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: orderTrend?.map(i => i.orders),
                type: 'line',
                smooth: true,
            },
        ],
        tooltip: {
            trigger: 'axis',
        },
    };

    const pieOption = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: '5%',
            left: 'center',
        },
        series: [
            {
                name: 'Status',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2,
                },
                label: {
                    show: false,
                    position: 'center',
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: orderStatus
            },
        ],
    };

    const getOrderData = async () => { setOrderData(await orderMetrics()); setIsLoading(false); };
    const getProductData = async () => { setProductData(await productMetrics()); setIsLoading(false); };
    const getSalesTrend = async () => setOrderTrend(await salesTrend());
    const getOrderGroup = async () => setOrderStatus(await orderStatusGroup());

    useEffect(() => {
        getOrderData();
        getProductData();
        getSalesTrend();
        getOrderGroup();
    }, []);

    return (
        <div className="px-10 pt-5 font-sans">
            <div className="py-4 text-lg font-medium">
                Hi Admin, Welcome back!
            </div>
            <div className="grid grid-cols-4 gap-4">
                <Tile
                    label="Total Orders"
                    value={orderData?.totalOrders as number}
                    color="red"
                    isLoading={isLoading}>
                    <AiFillDollarCircle size="2rem" className="text-red-800" />
                </Tile>
                <Tile
                    label="Orders Today"
                    value={orderData?.todaysOrders as number}
                    color="blue"
                    isLoading={isLoading}>
                    <RiLineChartLine size="2rem" className="text-blue-800" />
                </Tile>
                <Tile
                    label="Total Products"
                    value={productData?.totalProducts as number}
                    color="green"
                    isLoading={isLoading}>
                    <FiPackage size="2rem" className="text-green-800" />
                </Tile>

                <Tile
                    label="Active Products"
                    value={productData?.activeProducts as number}
                    color="yellow"
                    isLoading={isLoading}>
                    <BsPatchCheckFill size="2rem" className="text-yellow-800" />
                </Tile>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="my-5 rounded-md bg-white p-5">
                    <div className="text-lg font-medium">Sales</div>
                    <hr />
                    <ReactECharts option={options} />
                </div>
                <div className="my-5 rounded-md bg-white p-5">
                    <div className="text-lg font-medium">Orders by Status</div>
                    <hr />
                    <ReactECharts option={pieOption} />
                </div>
            </div>
        </div>
    );
};
