import { Alert } from 'evergreen-ui';
import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import { useAuth } from '../helpers/UseAuth';

export const Login = () => {

    const [emailAddress, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<{ error: boolean, message: string }>({ error: false, message: '' })
    const auth = useAuth();

    const singIn = async (event: any) => {
        event.preventDefault();
        try {
            await auth?.signIn(emailAddress, password);
        } catch {
            setError({ error: true, message: 'Failed to login' })
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img className="mx-auto h-20 w-auto" src={logo} alt="Workflow" />
                    <h2 className="mt-6 text-center text-3xl font-normal text-gray-900 font-sans">
                        Sign in to your account
                    </h2>
                </div>
                <div className="shadow-xl p-5 bg-white rounded-md">
                    <form className="mt-8 space-y-6" onSubmit={(event) => singIn(event)}>
                        <input type="hidden" name="remember" value="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div className='my-2'>
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <input id="email-address" value={emailAddress} onChange={(event) => setEmail(event.target.value)} name="email" type="email" autoComplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">Password</label>
                                <input id="password" value={password} onChange={(event) => setPassword(event.target.value)} name="password" type="password" autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm">
                                <a className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>

                        <div>
                            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Sign in
                            </button>
                        </div>
                    </form>
                    {error.error && <Alert marginTop={15} intent="danger" title={error.message}></Alert>}
                </div>
                <div className="text-sm text-center">Mazcart UAE - CMS</div>
            </div>
        </div>
    )
}



