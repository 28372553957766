import { ListItem, Tooltip } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { OrderToolbar } from '../components/OrderToolbar';
import { getOrders } from '../services/OrderService';
import { endsWith, includes, isEmpty, startsWith } from 'lodash';

export const Orders = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState<any>('');
    const [showExport, setShowExport] = useState(false);
    const [filteredData, setFilteredData] = useState<any>('');

    useEffect(() => { getData() }, [])

    useEffect(() => {
        if (orders) {
            setShowExport(new Set(orders.map((item: any) => item.status)).size == 1);
        }
    }, [orders])

    const getData = async () => {
        let data = await getOrders();
        console.log(data);
        setFilteredData(data);
        setOrders(data);
        setIsLoading(false);
    };

    const columns: GridColDef[] = [
        { field: 'id', width: 130, headerName: 'Order ID' },
        { field: 'orderDate', width: 130, headerName: 'Order Date' },
        { field: 'fullName', width: 130, headerName: 'Customer Name' },
        { field: 'deliveryAddress', width: 130, headerName: 'Delivery Address' },
        {
            field: 'products', width: 270, headerName: 'Product:Quantity:Price:Size',
            valueFormatter: (({ value }) => {
                let val = value as Array<any>;
                let str = val.map(item => `${item.productName},${item.quantity},${item.price},${item?.size}`);
                return (str);
            }),
            renderCell: ({ row }) => (
                <Tooltip title={<div>{row.products.map((item: any) => <div>{item.productName} : {item.quantity} : {item.price} c</div>)} </div>}>
                    <div>
                        {row.products.map((item: any) => <span className='border-r-2 p-2'>{item.productName} : {item.quantity} : {item.price} : {item?.size}</span>)}
                    </div>
                </Tooltip>
            )
        },
        { field: 'totalAmount', width: 130, headerName: 'Total Amount' },
        { field: 'deliveryCharge', width: 130, headerName: 'Shipping Charge' },
        { field: 'status', width: 130, headerName: 'Status', type: 'singleSelect', valueOptions: ['New', 'Exported'] }

    ];

    const handleFilter = (model: any, details: any) => {
        console.log('🚀 ~ file: Orders.tsx ~ line 63 ~ handleFilter ~ model', model);
        const field = model.items[0].columnField;
        const value = model.items[0]?.value;
        const operator = model.items[0].operatorValue;
        let filteredVal;

        switch (operator) {
            case 'contains':
                filteredVal = orders.filter((item: any) => !value ? true : includes(item[field].toString(), value));
                break;
            case 'equals':
            case 'is':
                filteredVal = orders.filter((item: any) => !value ? true : item[field].toString() === value);
                break;
            case 'not':
                filteredVal = orders.filter((item: any) => !value ? true : item[field].toString() !== value);
                break;
            case 'startsWith':
                filteredVal = orders.filter((item: any) => startsWith(item[field].toString(), value));
                break;
            case 'endsWith':
                filteredVal = orders.filter((item: any) => endsWith(item[field].toString(), value));
                break;
            case 'isEmpty':
                filteredVal = orders.filter((item: any) => isEmpty(item[field].toString()));
                break;
            case 'isNotEmpty':
                filteredVal = orders.filter((item: any) => !isEmpty(item[field].toString()));
                break;
            default:
                break;
        }
        console.log('Filtered Val', filteredVal);

        if (filteredVal) {
            setFilteredData(filteredVal);
            setShowExport(new Set(filteredVal.map((item: any) => item.status)).size == 1);
        }
    }

    return (
        <div className="px-10 pt-5">
            <div className='h-100 w-full bg-white' >
                <DataGrid
                    loading={isLoading}
                    rows={orders}
                    columns={columns}
                    components={{ Toolbar: OrderToolbar }}
                    componentsProps={{
                        toolbar: { showExport, filteredData, getData }
                    }}
                    // onSelectionModelChange={(newSelectionModel) => { setSelectedID(newSelectionModel) }}
                    // pageSize={5}
                    rowsPerPageOptions={[5, 10, 25]}
                    getRowClassName={(params) => params.getValue(params.id, 'status') === 'Exported' ? 'bg-green-100' : ''}
                    checkboxSelection={false}
                    disableSelectionOnClick
                    onFilterModelChange={(model, details) => handleFilter(model, details)}
                />
            </div>
        </div>
    )
}
