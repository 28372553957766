import {
    collection, deleteDoc, doc, DocumentData, getDocs, query,
    serverTimestamp, setDoc, where, updateDoc
} from "firebase/firestore";
import { firestore } from '../utils/Firebase';

const store = firestore();
const collectionRef = collection(store, 'products');

export const AddProduct = async (values: any) => {
    const id = `PN-${Math.random().toString(10).slice(12).toUpperCase()}`;
    const q = query(collectionRef, where("productName", "==", values.productName));
    const dataToCreate = {
        ...values,
        id: id,
        createTimestamp: serverTimestamp(),
        updateTimestamp: serverTimestamp()
    }
    const docs = await getDocs(q);
    if (docs.size > 0) {
        return { code: 400, message: 'Product already exist' }
    } else {
        await setDoc(doc(collectionRef, id), dataToCreate);
        return { code: 200, message: 'Product addes successfully' }
    }

}

export const GetProducts = async () => {
    let productList: DocumentData[] = [];
    const products = await getDocs(collectionRef);
    products.forEach(
        res => productList.push(res.data())
    )
    return productList;
}

export const DeleteProducts = async (selectedID: string[]) => {
    const q = query(collectionRef, where('id', 'in', selectedID));
    const res = await getDocs(q);
    res.forEach(async doc => await deleteDoc(doc.ref));
    return { code: 200, message: 'Product deleted successfully' }
}

export const UpdateProduct = async (values: any) => {
    const docRef = doc(collectionRef, values.id)
    const res = await updateDoc(docRef, values);
    return { code: 200, message: 'Product updated successfully' }
}

export const productMetrics = async () => {
    const productResponse = await getDocs(collectionRef);
    const q = query(collectionRef, where("status", "==", "Active"));
    const activeProducts = await getDocs(q);
    return {
        totalProducts: productResponse.size,
        activeProducts: activeProducts.size
    }
}